@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$accent-color: #f1dd46;
$second-accent-color: #FF013E;
$bg-color: #000;
$main-color: #fefefe;

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: $bg-color;
    color: $main-color;
}

.container {
    padding: 0 60px;
    padding-top: 95px;
    width: 100vw;
    max-width: 1440px;
}

h2 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 900;
}

.square-block {
    text-align: center;
    border-radius: 10px;
    border: 3px solid $accent-color;

    h3 {
        font-weight: bold;
        font-size: 20px;
        color: $accent-color;
        margin: 10px 0;
    }
}

@media screen and (max-width: 1020px) {
    .container {
        padding: 0 10px;
        padding-top: 90px;
    }
    body { 
        text-align: center;
    }

    .square-block {
        p {
            font-size: 12px;
        }
        h3 {
            font-size: 1rem;
        }
    }
}