@import '../../../index.scss';

.faq-list {
    list-style: none;
    padding: 0;
}

.faq-item {
    margin-bottom: 15px;
}

.faq-question {
    opacity: .7;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s;
    &:hover {
        opacity: 1; 
    }
}

.faq-answer {
    padding: 10px;
    margin-top: 5px;
    background-color: rgba($accent-color, .3);
    border-radius: 5px;
    font-size: 16px;
}
