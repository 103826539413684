@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');

@import '../../../index.scss';

#price {
    h2 {
        font-size: 35px;
        span{
            font-family: "Londrina Outline", sans-serif;
            font-style: normal;
            color: $accent-color;
            font-weight: 500;
        }
    }

    thead td {
        p {
            text-align: center;
            margin-top: 2px;
            padding: 2px;
            background-color: $second-accent-color;
            border-radius: 5px;
        }
    }   

    .first-table {
        tbody th p{
            transform: rotate(-90deg);
            padding: 5px;
            background-color: $second-accent-color;
            border-radius: 5px;
            width: 100px;
            font-weight: 500;
        }
    
        thead td:first-child {
            background: none;
        }
    
        tbody td {
            border-left: 2px solid $main-color;
        }
        .list {
            border: none;
        }
    }

    .price {
        font-weight: 800;
        font-size: 18px;
    }

    .second-table {
        max-width: 90vw;
        thead td:first-child {
            text-align: center;
            margin-top: 2px;
            background-color: $second-accent-color;
            border-radius: 5px;
            font-weight: 500;
        }
        thead p {
            display: none;
        }
        tbody {
            text-align: center;
            :first-child {
                text-align: left;
            }
        }
        .price {
            font-weight: 400;
        }
    }
    
    .price-block {
        padding-bottom: 230px;
        position: relative;
        text-align: center;
    }

    .price-bg {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        opacity: .5;
        max-height: 40vh;
    }

}

@media screen and (max-width: 1200px) {
    .first-table {
        tbody th {
            max-width: 60px;
        }
    }   
}
