footer {
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding-bottom: 20px;
}

.footer-container {
    height: 100%;
    max-height: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr 1fr;
    gap: 20px;  
    text-align: center;
    position: relative;
    .map {
        height: 100%;
        grid-row: 1 / 3;
        iframe {
            border-radius: 10px;
            width: 100%;
            height: 100%;
        }
    }
    p {
        text-transform: uppercase;
        opacity: .8;
        font-size: 12px;
        font-weight: 500;
    }
    h5 {
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0;
        text-transform: uppercase;
    }
    .info {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        grid-row: 1 / 3;
    }
    .blocks-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 20px;
        height: 100%;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1050px) {
    .footer-container {
        display: block;
        max-height: none;
        .map {
            iframe {
                height: 50vh;
            }
        }
        .info {
            display: block;
        }
        .blocks-container {
            display: block;
        }
        .square-block {
            margin-bottom: 20px;
        }
    }

    .footer-bg {
        bottom: auto;
        top: 300px;
        right: 0;
    }
}