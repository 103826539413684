@import '../../index.scss';

#home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        position: absolute;
        top: 0;
        z-index: -99;
    }
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    display: flex;
    justify-content: center;
    transition: .5s;
    a:hover {
        color: $accent-color;
    }
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600;

        img {
            height: 55px;
        }
    }

    .container {
        padding: 20px 40px;
        height: auto;
        min-height: auto;
    }
}

.uppercase {
    text-transform: uppercase;
}

.nav-burger {
    display: none;
    img {
        height: 50px;
    }
    span {
        display: block;
        width: 20px;
        height: 2px;
        margin: 2px 0;
        background-color: $main-color;
    }
    &__list {
        width: 100%;
        grid-column: 1 / 4;
        a {
            border-bottom: 1px solid $main-color;
        }
        li {
            padding: 5px 0;
            margin: 5px 0;
            font-size: 20px;
        }
    }
    .call-btn {
        background-color: $accent-color;
        padding: 10px;
        border-radius: 5px;
    }
}

.header-bg {
    width: 100%;
    z-index: -1;
    position: relative;
    video {
        width: 100%;
        object-fit: cover;
        max-height: 70vh;
    }
}

.header-frame {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;
    img {
        width: 100%;
    }
}

.header-bg::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($bg-color, .6);
}

.header-body {
    display: flex;
    justify-content: center;
    background-color: $bg-color;
    width: 100%;
    padding: 0 20px;
    position: relative;
    text-align: left;
    font-weight: 500;
    div {
        max-width: 1440px;
    }
}

header > .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0;
    h1 {
        font-size: 4rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
}

.scrolled {
    backdrop-filter: blur(30px);
    transition: .5s;
}

.hide {
    display: none;
}

.short-advantages {
    li {
        list-style:inside;
    }
}

.short-services {
    margin: 20px 20px;
    text-align: left;
    font-weight: 800;
    p {
        font-weight: 600;
        font-size: 1.5rem;
    }
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        font-size: 18px;
        img {
            height: 20px;
        }
    }
    li {
        display: grid;
        grid-template-columns: 20px auto;
    }
}

@media screen and (max-width: 1020px) {
    .nav-burger {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
    }

    .nav {
        display: none !important;
    }
    
    .header-body {
        padding: 0 30px;
    }

    .header-bg {
        width: 100%;
        z-index: -1;
        position: relative;
        video {
            width: 100%;
            object-fit: cover;
            max-height: 60vh
        }
    }
}