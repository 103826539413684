@import '../../index.scss';

main {
    display: flex;
    flex-direction: column;
    align-items: center;   
}

.gallery-container {
    video {
        height: 70vh;
        border-radius: 10px;
    }
}

.reviews-container {
    display: grid;
    grid-template-columns: auto 2fr;
    gap: 20px;
}

.reviews-wrapper {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.reviews-block {
    text-align: center;
    h4 {
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 500;
    }
    p {
        margin-bottom: 30px;
    }
    &__profile {
        margin-top: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        p {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
            color: $accent-color;
        }
        span {
            opacity: .5;
        }
    }
}

.reviews-form {
    grid-column: 2 / 3;
    border-radius: 10px;
    border: 1px solid $main-color;
    padding: 20px;
    margin-top: 20px;
    max-width: 400px;
    text-align: center;
    font-size: 16px;
    form div {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
    label {
        font-weight: 600;
    }
    input, textarea {
        border: 1px solid $main-color;
        padding: 2px 5px;
        border-radius: 5px;
        margin: 5px 0;
    }
    textarea {
        min-height: 100px;
        width: 100%;
    }
    h3 {
        font-size: 30px;
        font-weight: 600;
    }
    button {
        background-color: $accent-color;
        font-weight: 600;
        padding: 5px;
        border-radius: 5px;
        color: #000;
        &:hover {
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 1030px) {
    .gallery-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gallery-container {
        width: 80vw;
        video {
            height: 50vh;
        }
    }
    .reviews-container {
        gap: 0;
    }
    .reviews-form {
        grid-column: 1 / 2;
        max-width: none;
        width: 100%;
        input, button {
            height: 35px;
        }
    }
    .reviews-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}